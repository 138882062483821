<template>
  <div class="modal is-active" key="camera-modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ camera.id ? $t("camera.update") : $t("camera.create") }}
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">{{ $t("project.project") }}</label>
          <div class="control">
            <span class="select" style="width: 100%">
              <select v-model="camera.project_id" style="width: 100%">
                <option
                  v-for="project in projects"
                  :key="project.id"
                  :value="project.id"
                >
                  {{ project.name }}
                </option>
              </select>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">{{ $t("camera.name") }}</label>
          <div class="control">
            <input class="input" type="text" v-model="camera.name" required />
          </div>
          <p
            class="help is-danger"
            v-if="camera.name == '' && formValidation == false"
          >
            {{ $t("camera.createFormRequirements.name") }}
          </p>
        </div>
        <div class="field">
          <label class="label">{{ $t("project.timezone") }}</label>
          <div class="control">
            <span class="select" style="width: 100%">
              <select v-model="camera.timezone" style="width: 100%">
                <option
                  v-for="timezone in timezones"
                  :key="timezone"
                  :value="timezone"
                >
                  {{ timezone }}
                </option>
              </select>
            </span>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="field">
              <label class="label"> IP </label>
              <p class="control">
                <input class="input" type="text" v-model="camera.ip" required />
              </p>
            </div>

            <p
              class="help is-danger"
              v-if="camera.ip == 0 && formValidation == false"
            >
              {{ $t("camera.createFormRequirements.ip") }}
            </p>
          </div>
          <div class="column is-3">
            <div class="field">
              <label class="label"> Port </label>
              <p class="control">
                <input
                  class="input"
                  type="text"
                  v-model="camera.port"
                  required
                />
              </p>
              <p
                class="help is-danger"
                v-if="camera.port == '' && formValidation == false"
              >
                {{ $t("camera.createFormRequirements.port") }}
              </p>
            </div>
          </div>
          <div class="column is-3">
            <div class="field">
              <label class="label">{{ $t("camera.channel") }}</label>
              <p class="control">
                <input
                  class="input"
                  type="number"
                  v-model="camera.channel"
                  required
                />
              </p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">{{ $t("camera.username") }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="camera.username"
                  required
                />
              </div>
              <p
                class="help is-danger"
                v-if="camera.username == '' && formValidation == false"
              >
                {{ $t("camera.createFormRequirements.username") }}
              </p>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">{{ $t("camera.password") }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="camera.password"
                  required
                />
              </div>
              <p
                class="help is-danger"
                v-if="camera.password == '' && formValidation == false"
              >
                {{ $t("camera.createFormRequirements.password") }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
          <p class="control">
            <button
              :class="['button', 'is-success', isLoading ? 'is-loading' : '']"
              @click="createOrUpdate"
            >
              {{ camera.id ? $t("camera.update") : $t("camera.create") }}
            </button>
          </p>
          <p class="control">
            <button
              class="button"
              :disabled="checkOnfivConnectionLoading"
              @click="checkOnvifConnection"
            >
              {{ $t("camera.checkOnvifConnButtonTitle") }}
            </button>
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { timezones } from "@/assets/timezones.js";
export default {
  props: {
    camera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formValidation: true,
      isLoading: false,
      checkOnfivConnectionLoading: false,
      timezones: timezones,
    };
  },
  computed: {
    ...mapState({
      cameras: (state) => state.cameras,
      projects: (state) => state.projects,
    }),
  },
  methods: {
    ...mapActions(["refreshState"]),
    async createOrUpdate() {
      if (this.validateForm() == false) {
        this.formValidation = false;
        return;
      }
      this.isLoading = true;
      let formData = new FormData();

      formData.append("name", this.camera.name);
      formData.append("ip", this.camera.ip);
      formData.append("port", this.camera.port);
      formData.append("username", this.camera.username);
      formData.append("password", this.camera.password);
      formData.append("channel", this.camera.channel);
      formData.append("project_id", this.camera.project_id);
      formData.append("timezone", this.camera.timezone);

      console.log("id:", this.camera.id);

      let response = null;

      if (this.camera.id == 0 || this.camera.id == null) {
        formData.append("op", "create_camera");
        response = await this.$http.post("camera", formData);
        this.camera.id = response.data.id;
        this.$toast.success(this.$t("camera.createSuccess"));
      } else if (this.camera.id > 0) {
        formData.append("op", "update_camera");
        formData.append("id", this.camera.id);
        response = await this.$http.put("camera", formData);
        this.$toast.success(this.$t("camera.updateSuccess"));
      }
      console.log("result", response);

      if (response.status >= 400) {
        this.$toast.error(this.$t("camera.createError"));
        this.isLoading = false;
        return;
      } else {
        this.refreshState();
        this.isLoading = false;
      }
    },
    checkOnvifConnection() {
      if (this.checkOnfivConnectionLoading == true) {
        return;
      }
      if (this.validateForm() == false) {
        this.formValidation = false;
        return;
      }
      let formData = new FormData();
      formData.append("ip", this.camera.ip);
      formData.append("port", this.camera.port);
      formData.append("username", this.camera.username);
      formData.append("password", this.camera.password);
      this.$http.post("check-onvif-connection", formData).then(() => {
        this.$showToastWithType(
          this.$t("camera.cameraConnectionSuccess"),
          "success"
        );
      });
    },
    validateForm() {
      if (
        this.camera.project_id == "" ||
        this.camera.name == "" ||
        this.camera.ip == "" ||
        this.camera.port == "" ||
        this.camera.username == "" ||
        this.camera.password == ""
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style></style>
